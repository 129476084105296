import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
  host: {
     class: 'card custom-card overflow-hidden warning',
    "[class.__band]":"!item?.start?.active",
  }
})
export class CourseCardComponent implements OnInit {
  public img: string = '';
  public imgInstitution: string = '';
  public  apiUrl = environment.apiUrl;
  @Input() item: any;

  typeName = {
    'ONLINE': 'En linea',
    'INSITE': 'Presencial',
    'HYBRID': 'Hibrido'
  }

  constructor() { }

  ngOnInit(): void {
    this.imgInstitution = (this.item.institutionData) ? (`${this.apiUrl}${this.item.institutionData.image}`):"";
    this.img = (this.item.userData?.image) ? (`${this.apiUrl}${this.item.userData.image}`):"";
  }

}
