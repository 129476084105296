import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/iterfaces';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'web-exam',
  templateUrl: './web-exam.component.html',
  styleUrls: ['./web-exam.component.scss']
})
export class WebExamComponent implements OnInit {
 
  public login : boolean = true;

  public user: IUser = {}

  public code: string = '';
  public exams: any;
  constructor(
    public userService: UserService,
    private httpService: HttpService,
    private router: Router,
    private alertService: AlertService
  ) { }

  async ngOnInit() { 
    this.user = this.userService.userEmployeeExam; 
    try {
      let examenes = await this.httpService.get(`api/kardex/exams`).toPromise();
      this.exams = examenes.exams;
    } catch (error) {
      if (error?.status != 403)this.alertService.errorToast(error.error.message);
    }
  }

  public loadExamn(params:any):void{
    this.router.navigate(['/answer-exam'],{ queryParams:  params });
  }

  signOutHandler() {
    this.userService.signOutCurrentUser();
  }
}
