import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/iterfaces';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../shared/services/alert.service';
import * as moment from 'moment-timezone';


@Component({
  selector: 'modal-exam',
  templateUrl: './modal-exam.component.html',
  styleUrls: ['./modal-exam.component.scss']
})
export class ModalExamComponent implements OnInit {

  public isLoading: boolean = true; 
  public login : boolean = true;
  public qs: any[] = [];
  public response: any[] = [];
  public q: any;
  public _index: number = 0;
  public user: IUser = {}
  public id_curse: string = '';
  public id_certificate: string = ''; 
  public id_exam: string = '';
  public progress: string = '';
  public _id: string = '';
  public rate: any;
  public counter: number = 0;
  private isAnyText: boolean = false;
  timerInterval;

  private FormQuestions: FormGroup; 

  constructor(
    public userService: UserService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alertService: AlertService,
    public router: Router
  ) { 
    // window.addEventListener("beforeunload", function (e) {
    //   var confirmationMessage = "Esta seguro?";
    // console.log("entro");
    
    //   e.returnValue = confirmationMessage;    
    //   return confirmationMessage;              
    // });

  }

  ngOnInit(): void { 
    this.user = this.userService.userEmployeeExam; 
    this.route.queryParams.subscribe((resp:any) => {
      console.log(resp);
      
      this.id_curse = resp.cu;
      this.id_certificate = resp.ce; 
      this.id_exam = resp.ex;
      this._id = resp.id_join;
      
      this.httpService.get(`api/exam/start/${this.id_certificate}/${this.id_curse}/${this.id_exam}`).toPromise().then((resp:any) => {
         
        this.qs = resp.questions;
        this.q = this.qs[0];
        this.counter = (resp.time||0)*60;
        this.initTimer();
        this.qs.forEach(question => {
          if (question.type == "TEXT") {
            this.isAnyText = true;
          }
          this.response.push({name: question.name, value: question.type == 'MULTIPLE'?[]:''});
        });
        this.isLoading = false;
      }).catch((err: any) => {
        this.isLoading = false;
        
      })
    });

    this.FormQuestions = this.fb.group({
      id: [this._id, Validators.required],
      course:[this.id_curse, Validators.required],
      exam: [this.id_exam, Validators.required],
      responses: [[], Validators.required],
    });
  }

  ngOnDestroy() {
    clearInterval(this.timerInterval);
  }

  public next(){
    if (this._index < this.qs?.length) {this._index++;}
    this.getProgressPercent();
    this.q = this.qs[this._index];
  }
  
  public prev(){
    if (this._index > 0) {this._index--;}
    this.getProgressPercent();
    this.q = this.qs[this._index];
  }
  
  public getProgressPercent(){
    this.progress = (((this._index + 1) / this.qs?.length) * 100).toString();
    return this.progress;
  }

  public eventQuestion(event: any, name: string, type: string):void{
    const question = this.response.map((e) => { return e.name; }).indexOf(name);
    if (type != "MULTIPLE") {
      question >= 0? this.response[question].value = event : '';
    }else {
      const option = question >= 0?this.response[question].value?.indexOf(event): -1
      option >= 0? this.response[question].value.splice(option, 1) : this.response[question].value.push(event);
    }
  }

  public isSelected(option:string, type:string):boolean{
    let selected = false
    if (type == "MULTIPLE") {
      selected = this.response[this._index].value.indexOf(option) >= 0
    }else{
      selected = this.response[this._index].value == option;
    }
    return selected;
  }

  async submit(timeOut: boolean = false){ 
    try {
      this.isLoading = true;
      let isOneEmpty = false;
      for (let i = 0; i < this.response.length; i++) {
        const element = this.response[i];
        element.value.length == 0 ? isOneEmpty = true:"";
      }

      // this.response = this.response.filter(e => {return e.value != ''});

      if (isOneEmpty && !timeOut) {
        this.isLoading = false;
        this.alertService.infoToast("Preguntas incompletas, verifique porfavor!");
      }else{
        this.FormQuestions.value.responses = this.response;
        this.httpService.post(`api/exam`,this.FormQuestions.value).subscribe(response=>{
          this.isLoading = false;
          clearInterval(this.timerInterval);
          this.isAnyText?(this.alertService.info("Espera tu calificacion pronto","", "Aceptar")):(this.alertService.info((response.approved?'Aprobaste':'Reprobaste')+'\n Calificacion: '+response.score,"", "Aceptar"));
          this.router.navigate(['/kardex']);
        },error => {
          this.isLoading = false;
          if (error?.status != 403)this.alertService.errorToast(error.error.message);
        })
      }
      
    } catch (error) {
      this.isLoading = false;
      this.alertService.warningToast(error.error.message);
    }
  } 

  public initTimer(){
    this.timerInterval = setInterval(()=>{
      this.counter--;
      if (this.counter == 0) {
       this.submit(true); 
      }
    },1000)
  }
}
