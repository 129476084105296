import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'certification-card',
  templateUrl: './certification-card.component.html',
  styleUrls: ['./certification-card.component.scss'],
  host: {
    class: 'card custom-card overflow-hidden'
  }
})
export class CertificationCardComponent implements OnInit {
  public img: string = '';
  public  apiUrl = environment.apiUrl;
  @Input() item: any;
  @Input() showDesc: boolean = true;
  @Input() showProg: boolean = false;

  constructor() { }

  ngOnInit(): void {  
    this.img = `${this.apiUrl}${this.item.image || this.item.img}`;
  }

}
