<!-- Page -->
<div class="page main-signin-wrapper bg-primary construction">

  <div class="container ">
      <div class="construction1 text-center details text-white">
          <div class="row align-items-center">
              <div class="col-lg-12">
                  <h1 class="tx-140 mb-0">404</h1>
              </div>
              <div class="col-lg-12 ">
                  <h1>Lo sentimos, página no encontrada !</h1>
                  <h6 class="tx-15 mt-3 mb-4 text-white-50">Parece que la página que ha buscado fue movida o nunca existió</h6>
                  
                  <a class="btn ripple btn-success text-center" href="/calendar">Ir a inicio</a>
              </div>
          </div>
      </div>
  </div>

</div>
<!-- End Page -->