import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-profile',
  templateUrl: './modal-profile.component.html',
  styleUrls: ['./modal-profile.component.scss']
})
export class ModalProfileComponent implements OnInit {

  public apiUrl: string = '';
  public imagen: string = ''; 
  public urlImage: string = '';
  public noImage: string = '';
  constructor(public userService: UserService, private modalService: NgbModal) { this.apiUrl = environment.apiUrl}

  ngOnInit(): void {   
    this.apiUrl = this.userService.login.company.server; 
    this.noImage = '../../../assets/img/placeholder-user.png';  
    this.imagen = `${this.apiUrl}${this.userService.userEmployeeExam.image}`; 

    this.userService.data.subscribe((resp: any) => { 
      this.imagen = resp;
    })
  }
  


  public openModal(modal) { this.modalService.open(modal, {size: 'sm'}); }

}
