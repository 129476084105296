import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './shared/services/token.interceptor.service'; 

// Imports Tables
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator'; 
import { NgApexchartsModule } from 'ng-apexcharts';
import { WebExamComponent } from './pages/web-exam/web-exam.component';
import { AuthenticationExamComponent } from './authentication-exam/authentication-exam.component';   
import { ModalExamComponent } from './pages/web-exam/components/modal-exam/modal-exam.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { registerLocaleData } from '@angular/common';
import LocaleEs from '@angular/common/locales/es';

registerLocaleData(LocaleEs, 'es');
@NgModule({
  declarations: [
    AppComponent,
    WebExamComponent,
    AuthenticationExamComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    RouterModule,
    SharedModule,
    CarouselModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,  
    SweetAlert2Module.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'es'}  
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
