<div class="page-header">
  <div>
      <h2 class="main-content-title tx-24 mg-b-5">Examenes</h2>
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">LMS</a></li>
          <li class="breadcrumb-item active" aria-current="page">Examenes</li>
      </ol>
  </div>
  <div class="d-flex justify-content-end col-sm-12 col-md-12 col-lg-1 col-xl-1 p-0">
    <!-- <div class="input-group date custom-card mb-0" style="min-width: 225px;">
      <div class="justify-content-center">
          <div class="input-group date custom-card mb-0" style="width: 225px;">
              <div class="input-group-prepend">
                  <div class="input-group-text">
                      <i class="fe fe-calendar lh--9 op-6"></i>
                  </div>
              </div>
              <input class="form-control" theme="dp-material"
              [(ngModel)]="filterDate"
              ngxDaterangepickerMd opens="left"
              [locale] = dateOptions
              [ranges] = dateRanges 
              [autoApply]="true"
              readonly
              [showCustomRangeLabel]="true"
              (change)="selectedDate($event)"
               >
          </div>
      </div>
    </div> -->
</div>
</div>
<!-- End Page Header -->

  <div class="row justify-content-between">
    <div class="col-sm-12 col-md-6 _list">

      <ng-container *ngIf="exams?.length > 0 else noExam">
        <div class="card custom-card overflow-hidden"  *ngFor="let exam of exams">
          <div class="card-body _exm">
              <div class="row ">
                  <div class="col-8">
                    <span class="mb-0 text-muted">Curso</span>
                      <h2>{{ exam.name_course}}</h2>
                      <h5 class="m-0"><b class="text-primary">{{nameCertification}}:</b> {{exam.name_certificate}}</h5>
                  </div>
                  <div class="col-4 d-flex flex-column justify-content-end">
                    <h4 class="text-right mb-2"><span class="text-warning">Intentos:</span> {{ exam.exam_complete || 0}}/ {{ exam.exam_opp || 0}}</h4>
                    <span class="text-right tx-12 text-muted mb-2">Cada intento es considerado.</span>
                      <div>
                          <button class="btn ripple btn-block btn-primary" [swal]="startSwal" (confirm)='loadExamn({ce: exam.id_certificate, cu:exam.id_course, ex: exam.id_exam, id_join:exam.id_join})'><h4 class="my-0">Comenzar <i class="fa fa-chevron-right ml-1"></i></h4></button>
                      </div>
                  </div>
              </div>
                  
          </div>
      </div>
    </ng-container> 
    </div>
    <div class="col-sm-12 col-md-6 col-lg-5">
        <div class="card custom-card __instructions">
            <i class="fa fa-file-text"></i>
            <h2>Bienvenido a <b>Examenes</b></h2>
            <p>Aquí puedes encontrar tus examenes pendientes por contestar.</p>
          </div>
    </div>
  </div>

<ng-template #noExam>
    <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-7" >
            <div class="card custom-card">
                <div class="card-body _exm">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="m-0 text-muted">Sin examen disponible</h3>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</ng-template> 
    
<!-- SWAL -->
<swal #startSwal class="d-none" title="¿Estas seguro de iniciar tu examen?" text="Al iniciar contará como primer intento." icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>


 