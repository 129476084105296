<div class="info">
    <div class="name">
        <div class="icon"></div>
        <div class="title">
            <div class="top" *ngIf="!showResult">
                <span>Curso</span>
                <span>Intentos: <b>{{item.exam_complete || 0}} / {{ item.exam_opp || 0}}</b></span>
            </div>
            <h3 [class.mt-0]="showResult">{{item.name_course}}</h3>
        </div>
        <div class="desc">
            <span>{{nameCertification}}: <b>{{item.name_certificate}}</b></span>
            <span *ngIf="!showResult">Ir a examen <div class="arrow"><i class="fa fa-chevron-right"></i></div></span>
            <span *ngIf="showResult && item?.name_exam != 'EXAMEN NO REQUERIDO'" class="badge badge-light px-2 tx-12">Calificación: <b>{{item.score || "Pendiente de Calificación"}}</b></span>
        </div>
    </div>
    <!-- <div class="center">
        <h3 class="mt-0 mb-2"><i class="fa fa-file-text"></i>{{item.name_exam}}</h3>
        <span>Curso: <b></b></span>
    </div> -->
</div>
<!-- <div class="arrow"><i class="fa fa-chevron-right"></i></div> -->