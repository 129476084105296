import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MkImgGroupComponent } from './mk-img-group/mk-img-group.component';
// import { PipesModule } from '../shared/pipes/pipes.module';
import { MkUserComponent } from './mk-user/mk-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MkStatusComponent } from './mk-status/mk-status.component';
import { MKLoaderComponent } from './mk-loader/mk-loader.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { statusPipe } from '../shared/pipes/status.pipe';
import { CertificationCardComponent } from './certification-card/certification-card.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { DetailCourseComponent } from './detail-course/detail-course.component';
import { ExamCardComponent } from './exam-card/exam-card.component';
import { DetailCertificationComponent } from './detail-certification/detail-certification.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalProfileComponent } from './modal-profile/modal-profile.component';
import { UserImgModalComponent } from './user-img-modal/user-img-modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MkImgGroupComponent,
    MkUserComponent,
    MkStatusComponent,
    MKLoaderComponent,
    CertificationCardComponent, 
    CourseCardComponent, 
    DetailCourseComponent,
    ExamCardComponent,
    DetailCertificationComponent,
    UserImgModalComponent
  ],
  exports: [
    MkImgGroupComponent,
    MkUserComponent,
    MkStatusComponent,
    MKLoaderComponent,
    CertificationCardComponent, 
    CourseCardComponent, 
    DetailCourseComponent,
    ExamCardComponent,
    DetailCertificationComponent,
    UserImgModalComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    PipesModule,
    NgbModule,
    SweetAlert2Module.forRoot(),
  ],
  providers:[
    statusPipe,
    
  ]
})
export class ComponentsModule {}
