import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imageUser'
})
export class ImageUserPipe implements PipeTransform {

  transform(img: string): string { 
    let imgDefault = '../../../assets/img/placeholder-user.png';
    return `${environment.apiUrl}${img}`|| imgDefault;
  }

}
