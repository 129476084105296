import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DownloadServiceService } from 'src/app/shared/services/download-service.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

export interface modalI{
  type : string,
  courseid: string,
  certificateid: string,
  joinid: string
  status: string
}
export interface courseI{
  _id: string,
  type: string,
  title: string,
  description: string,
  date: string,
  generalObjetive: string,
  specificObjetive: string,
  index: Array<string>,
  address : String,
  duration: string,
  certificateBy: Object,
  exam: Array<Object>,
  files: Array<Object>,
  startExam: boolean
  status: boolean,
  start: any,
  availableDate: any
}
@Component({
  selector: 'detail-course',
  templateUrl: './detail-course.component.html',
  styleUrls: ['./detail-course.component.scss']
})

export class DetailCourseComponent implements OnInit {
  @Input() options: modalI;
  @Output() onCourseClose: EventEmitter<any> = new EventEmitter<any>();
  //@Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 

  @Input() isJoin: boolean = false;

  public detail: courseI;
  public certificateid:any;
  public apiUrl = environment.apiUrl;
  public img: string = ''; 
  public imgInstitution: string = ''; 
  public file: string = '';
  public detailCertification: any = null;
  public nameCertification: string = this.userService.user.nameCertification;

  @Input() join_id: string = '';

  typeName = {
    'ONLINE': 'En linea',
    'INSITE': 'Presencial',
    'HYBRID': 'Hibrido'
  }

  constructor(
    private httpService: HttpService,
    private router: Router,
    private alertService: AlertService,
    private downloads: DownloadServiceService,
    private userService: UserService ) { }

  ngOnInit(): void { 
    this.detailCertification = this.options.status; 
  }

  ngOnChanges():void{ 
    if(this.options.type == "EXAM") this.loadCourseByJoin();
    if(this.options.type == "DETAIL") this.loadCourseByID();
  }

  joinCertification(idCourse, idCertificate){
    //${this.options.certificateid}/${this.options.courseid}
    return this.httpService.post(`api/certificate/join/${this.options.certificateid}/${this.options.courseid}`,{}).toPromise()
    .then((resp: any) => { 
      this.detailCertification = resp.status;
      this.join_id = resp.id;
      this.alertService.successToast(resp.message);
      this.ngOnChanges();

    }).catch((err: any) => { 
      if (err?.status != 403) {
        this.alertService.errorToast(err.error.message);
      }
    });
  }

  async cancelCertification(idJoin){
    try {
      //${this.join_id}
      let resp = await this.httpService.post(`api/certificate/out/${idJoin}`,{}).toPromise();
      this.detailCertification = resp.status;
      this.alertService.successToast(resp.message);
      this.ngOnChanges();
      this.onCourseClose.emit();  
       

    } catch (error) {
      if (error?.status != 403) {
        this.alertService.errorToast(error.message);
      }
    }
  }


  async loadCourseByJoin(){
    try {
      let data = await this.httpService.get('api/certificate/course/employee/'+this.options.joinid+'/'+this.options.courseid).toPromise();
      this.certificateid = data._id;
      this.detail = data.course;
      this.img = `${this.apiUrl}${data?.course?.certificateBy?.userImage}`;
      this.imgInstitution = `${this.apiUrl}${data?.course?.certificateBy?.institutionImage}`; 
    } catch (error) {
      console.log(error); 
      if (error?.status != 403)this.alertService.error("Ocurrió un error", "No se pudo cargar el detalle del Curso", "Aceptar");
    }
  }
  
  async loadCourseByID(){
    try {
      await this.httpService.get('api/course/'+this.options.certificateid+'/'+this.options.courseid).toPromise() 
      .then((resp:any) => {  
        this.detail = resp;
        this.join_id = resp.idjoin;
        this.img = `${this.apiUrl}${resp.certificateBy.userImage}`;
        this.imgInstitution = `${this.apiUrl}${resp.certificateBy?.institutionImage}`;
        this.certificateid = this.options.certificateid;
      })
    } catch (error) {
      console.log(error);
      if (error?.status != 403)this.alertService.error("Ocurrió un error", "No se pudo cargar el detalle del Curso", "Aceptar");
    }
  }

  async openExam(examid){ 
    this.httpService.get(`api/exam/start/${this.certificateid}/${this.detail._id}/${examid}`).toPromise()
    .then((resp: any) => {
      this.router.navigate(['/answer-exam'],{ queryParams:{
      ce: this.certificateid, 
      cu: this.detail._id, 
      ex: examid, 
      id_join: this.options.joinid || this.join_id } });
      this.onCourseClose.emit(); 
    }).catch((err: any) => {
      if(err.status === 400){ 
        return this.alertService.infoToast(err.error.message);  
      } 
    });
 
  }

  toDataURL(url) {
    return fetch(url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        return URL.createObjectURL(blob);
      });
  }

  async download(file:string, nameOriginal:string) {
    console.log(`${this.apiUrl}${file}`);
    
    // const a = document.createElement("a");
    // a.href = await this.toDataURL("https://lmsback.fdkapp.com/ut5pWAthtbJCEIdyU1HFGQ==/271d358a2b9e890e0173267bcfc1f6a2");
    // a.download = "test.jpg";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    window.open(`${this.apiUrl}${file}`, '_blank');
  }

  ___download(file:string, nameOriginal:string): void {
    this.downloads
      .download(`${this.apiUrl}${file}`)
      .subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = nameOriginal;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
  }

  public close(): void{this.onCourseClose.emit();}

  public goToLink(link: string):void{
    let url = link.match(/^https?:/) ? link : '//' + link;
    window.open(url, '_blank');
  }

  public validateStartCourse(item: any):boolean{
    let start: boolean = false;
    if (moment(item?.availableDate?.dateStart) <= moment() && moment(item?.availableDate?.dateEnd) >= moment()) {
      start = false;
    }else start = true;

    return start;
  }


}
