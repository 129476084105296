import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'detail-certification',
  templateUrl: './detail-certification.component.html',
  styleUrls: ['./detail-certification.component.scss']
})
export class DetailCertificationComponent implements OnInit {

  public apiUrl = environment.apiUrl;
  public img: string = '';
  public imgInstitution: string = '';
  public detailCertification: any = null;
  public modalOptions = {  
    _idAcreditacion: null, 
    _idCourse: null, 
    type: "DETAIL", 
    joinid: null,
    status: null,
    certificateid: null,
    courseid: null
  };
  public isLoading: boolean = false;
  public nameCertification: string = this.userService.user.nameCertification;

  @Input() id: string;
  @Input() isJoin: boolean = false;
  @Input() join_id: string = '';
  @Input() type: string = 'DETAIL';

  constructor(private modalService: NgbModal,
              private httpService: HttpService,
              private alertService: AlertService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.modalOptions.type = this.type;  
  }

  ngOnChanges(): void{ 
    this.loadDetailCertification(this.id);
  }

  loadDetailCertification(_id: string){
    return this.httpService.get(`api/certificate/${_id}`).toPromise()
    .then((resp: any) => { 
      this.detailCertification = resp;
      this.img = `${this.apiUrl}${resp.image}`;  
      resp.institutionData?.image ? this.imgInstitution = `${this.apiUrl}${resp.institutionData?.image}`: "";
      this.isLoading = false; 
    }).catch((err: any) => {
      if (err?.status != 403) {
        this.alertService.errorToast(err.message);
      }
    });
  }

  // joinCertification(_id: string, idCourse: string){
  //   return this.httpService.post(`api/certificate/join/${_id}/${idCourse}`,{}).toPromise()
  //   .then((resp: any) => {
  //     this.detailCertification.status = resp.status;
  //     this.join_id = resp.id;
  //     this.alertService.successToast(resp.message);
  //   }).catch((err: any) => {
  //     console.log("Entro en el catch");
  //     this.alertService.errorToast(err.message);
  //   });
  // }

  // async cancelCertification(_id: string){
  //   try {
  //     let resp = await this.httpService.post(`api/certificate/out/${_id}`,{}).toPromise();
  //     this.detailCertification.status = resp.status;
  //     this.alertService.successToast(resp.message);
  //   } catch (error) {
  //     this.alertService.errorToast(error.message);
  //   }
  // }

  openModal(modal) {
    this.modalService.open(modal, { size: 'lg',});
  }

  public openCourseDetails(course: any, modal){
    this.modalOptions.status = this.detailCertification?.status; 
    this.modalOptions.certificateid = this.detailCertification?._id; 
    this.modalOptions.courseid = course._id; 
    this.modalOptions.joinid = this.join_id;

    this.modalService.open(modal, { size: 'lg',});
  }
}
