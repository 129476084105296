import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';

import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { FullscreenToggleDirective } from './directives/fullscreen-toggle.directive';
import { MessageLayoutComponent } from './layouts/message-layout/message-layout.component';
import { ErrorLayoutComponent } from './layouts/error-layout/error-layout.component';
import { SearchDatePipe } from './pipes/search-date.pipe'; 
import { OverlayComponent } from '../components/overlay/overlay.component';
import { GlobalAlertComponent } from '../components/global-alert/global-alert.component';
import { PipesModule } from './pipes/pipes.module';
import { ModalProfileComponent } from '../components/modal-profile/modal-profile.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [ 
    FooterComponent, 
    HeaderComponent, 
    LoaderComponent, 
    TapToTopComponent, 
    SidemenuComponent, 
    NotificationSidebarComponent, 
    FullLayoutComponent, 
    ContentLayoutComponent, 
    FullscreenToggleDirective,  
    MessageLayoutComponent, 
    ErrorLayoutComponent, 
    SearchDatePipe,
    OverlayComponent,
    GlobalAlertComponent,
    ModalProfileComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    PipesModule,
    ComponentsModule
  ],
  exports: [
    LoaderComponent, 
    TapToTopComponent,
    FooterComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    SearchDatePipe
  ],
})
export class SharedModule { }
