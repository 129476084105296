import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationExamComponent } from './authentication-exam/authentication-exam.component';
import { MkNotFoundComponent } from './components/mk-not-found/mk-not-found.component';
import { ModalExamComponent } from './pages/web-exam/components/modal-exam/modal-exam.component';
import { FullLayoutComponent } from './shared/layouts/full-layout/full-layout.component';
import { Pages_Routes } from './shared/routes/pages.routes';
import { AuthGuard } from './shared/services/auth-guard.service';


const routes: Routes = [
  { path: 'login', component: AuthenticationExamComponent },
  { path: 'answer-exam', component: ModalExamComponent }, 
  { path: '',  canActivate:[AuthGuard], component: FullLayoutComponent, children: Pages_Routes },
  { path: '**', component: MkNotFoundComponent },
]; 

 @NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
