<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Imagen de usuario</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<!-- <div class="modal-body py-0">
    <div class="img"> 
        <img [src]="img || noImage" alt="">
    </div>

      
    <div class="row">
        <div class="col-12">
            <h6>Cambiar Imagen</h6>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fa fa-camera lh--9 op-6"></i>
                    </div>
                </div>
                <input #file accept=".jpg, .jpeg, .png" (change)="selectImage(profileInstructor[i], $event, true)"  type="file" class="form-control">
               
            </div>
        </div>
    </div>
</div> -->


<div class="modal-body py-0 text-center">
    <div *ngFor="let item of profileInstructor ; index as i">
        <div class="css_imgContainer">
            <!--imagenPreview-->

            <div class="img">
                <ng-container *ngIf="profileInstructor[i].name != null "><i class="fa fa-trash" (click)="deleteImage(i, profileInstructor[i].id)"></i></ng-container>
                <img [src]="profileInstructor[i].name || noImage">
            </div>

            <div class="row mb-3">
                <div class="col-12" placement="top" ngbTooltip="Tamaño máximo 5 MB. Dimensiones recomendadas 200px x 200px" tooltipClass="tooltip-danger">
                    <label for="input" class="btn btn-{{profileInstructor[i].name == null ? 'dark' : 'info'}} btn-block lh-8 mb-0 cursor-pointer"><i
                            class="fa fa-{{profileInstructor[i].name == null ? 'upload' : 'refresh'}} mr-1"></i>
                        {{profileInstructor[i].name == null ? "Buscar Imagen" : "Cambiar Imagen"}}
                        <input [(ngModel)]="file" accept=".jpg, .jpeg, .png" id="input"
                            (change)="selectImage(profileInstructor[i], $event, true) " type="file" style="display: none;">
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" (click)="updateUserImage()" [disabled]="!file">Guardar</button>
</div>