 <div class="top p-3">
     <div class="img" (click)="openModal(img)">
        <div class="img">
            
            <img [src]="userService._employeeExam.image | imageUser" alt="">

            <i class="fa fa-eye"></i>
        </div>
     </div>
     <div class="info p-3">
         <div class="number text-primary d-flex justify-content-between">
             <b title="Número de empleado">#{{userService.userEmployeeExam.number}}</b>
            <!-- <span title="Empresa">{{}}</span> -->
         </div>
         <div class="name"><h4 class="m-0">{{userService.userEmployeeExam.name}}</h4></div>
         <div class="position text-muted">
            <span title="Puesto">{{userService.userEmployeeExam.position}}</span> &middot; <span title="Departamento">{{userService.userEmployeeExam.department}}</span>
         </div>
     </div>
 </div>
 <div class="bot pb-3 px-3">
     <div class="row">
         <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text" style="width: 100px;">
                        <i class="fa fa-building lh--9 op-6 mr-2"></i> <b>Empresa</b>
                    </div>
                </div>
                <input class="form-control" type="text" readonly [value]="userService.getSubdomain()">
            </div>
         </div>
         <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text" style="width: 100px;">
                        <i class="mdi mdi-email lh--9 op-6 mr-2"></i> <b>Correo</b>
                    </div>
                </div>
                <input class="form-control" type="text" readonly [value]="userService.user.email">
            </div>
         </div>
         <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text" style="width: 100px;">
                        <i class=" mdi mdi-cellphone lh--9 op-6 mr-2"></i> <b>Teléfono</b>
                    </div>
                </div>
                <input class="form-control" type="text" readonly [value]="userService.userMobile.phone">
            </div>
         </div>
         <!-- <div class="col-12">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" style="width: 100px;">
                        <i class=" mdi mdi-file-document lh--9 op-6 mr-2"></i> <b>RFC</b>
                    </div>
                </div>
                <input class="form-control" type="text" readonly [value]="userService.userMobile.rfc">
            </div>
         </div>
         <div class="col-12">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text" style="width: 100px;">
                        <i class=" mdi mdi-hospital lh--9 op-6 mr-2"></i> <b>NSS</b>
                    </div>
                </div>
                <input class="form-control" type="text" readonly [value]="userService.userMobile.nss">
            </div>
         </div> -->
     </div>
 </div>

 <ng-template #img let-modal>
    <user-img-modal (onClose)="modal.dismiss()"></user-img-modal>
 </ng-template>