import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'exam-card',
  templateUrl: './exam-card.component.html',
  styleUrls: ['./exam-card.component.scss'],
  host: {
    class: 'card custom-card px-3 overflow-hidden'
  }
})
export class ExamCardComponent implements OnInit {
  public nameCertification: string = this.userService.user.nameCertification;

  @Input() item: any;
  @Input() showResult: boolean = false;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

}
