<!-- Main Header-->
<div class="main-header side-header sticky">
    <!--  -->
    <div class="container-fluid">
        <!-- <div class="main-header-left deskIcon">
            <a class="main-header-menu-icon" href="javascript:void(0)" id="mainSidebarToggle" (click)="toggleSidebar()">
                <!- - <span></span> - ->
                <img src="../../../../assets/img/K-fdbk.svg" alt="">
            </a>
        </div>
        <div class="main-header-left mobileIcon">
            <a class="main-header-menu-icon" href="javascript:void(0)" id="mainSidebarToggle" (click)="toggleMobileSidebar()">
                <!- - <span></span> - ->
                <img src="../../../../assets/img/K-fdbk.svg" alt="">
            </a>
        </div> -->
        
        <div class="main-header-center">
            <!-- <div class="responsive-logo d-block d-md-none">
                <a routerLink="/dashboard"><img src="../../../../assets/img/brand/logo.svg" class="mobile-logo" alt="logo"></a>
                <a routerLink="/dashboard"><img src="../../../../assets/img/brand/logo-light.svg" class="mobile-logo-dark" alt="logo"></a> 
            </div> -->

            <app-global-alert></app-global-alert>
            
            <!--Mensaje de alerta!-->
            <!-- <ngb-alert type="danger" [dismissible]="false" style="margin-top: 12px; text-align: center;" *ngIf="userService.mensaje"> 
                <strong>{{userService.mensaje}}</strong>
            </ngb-alert>  -->

            <!-- <div class="input-group">
                <div class="input-group-btn search-panel">
                    <ng-select placeholder="All categories" class="form-control p-0">
                        <ng-option *ngFor="let category of categories" [value]="category.id">
                            {{category.name}}
                        </ng-option>
                    </ng-select>
                </div>
                <input type="search" class="form-control" placeholder="Search for anything...">
                <button class="btn search-btn"><i class="fe fe-search"></i></button>
            </div> -->

            
        </div>
        <div class="main-header-right">
            <!-- <div ngbDropdown class="header-search">
                <a ngbDropdownToggle class="nav-link icon header-search">
                    <i class="fe fe-search header-icons"></i>
                </a>
                <div ngbDropdownMenu>
                    <div class="main-form-search p-2">
                        <div class="input-group">
                            <div class="input-group-btn search-panel">
                                <ng-select placeholder="All categories" class="form-control">
                                    <ng-option *ngFor="let category of categories" [value]="category.id">
                                        {{category.name}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <input type="search" class="form-control" placeholder="Search for anything...">
                            <button class="btn search-btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div ngbDropdown class="main-header-notification flag-dropdown">
                <a ngbDropdownToggle class="nav-link icon country-Flag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" fill="#f0f0f0"/><g fill="#0052b4"><path d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z"/></g><g fill="#d80027"><path d="M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z"/><path d="M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z"/></g></svg>
                </a>
                <div ngbDropdownMenu>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex ">
                        <div class="lang-span">FR</div>
                        <div class="d-flex">
                            <span class="mt-2">French</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">GE</div>
                        <div class="d-flex">
                            <span class="mt-2">Germany</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">IT</div>
                        <div class="d-flex">
                            <span class="mt-2">Italy</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">RS</div>
                        <div class="d-flex">
                            <span class="mt-2">Russia</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">ES</div>
                        <div class="d-flex">
                            <span class="mt-2">spain</span>
                        </div>
                    </a>
                </div>
            </div> -->
            <!-- <div class="dropdown d-md-flex">
                <a class="nav-link icon full-screen-link" href="javascript:void (0)" appFullscreenToggle  >
                    <i class="{{toggleClass}} fullscreen-button fullscreen header-icons"></i>
                </a>
            </div> -->
            <!-- <div ngbDropdown class="main-header-notification">
                <a ngbDropdownToggle class="nav-link icon" href="javascript:void(0)">
                    <i class="fe fe-bell header-icons"></i>
                    <span class="badge badge-danger nav-link-badge">4</span>
                </a>
                <div ngbDropdownMenu>
                    <div class="header-navheading">
                        <p class="main-notification-text">You have 1 unread notification<span class="badge badge-pill badge-primary ml-3">View all</span></p>
                    </div>
                    <div class="main-notification-list">
                        <div class="media new">
                            <div class="main-img-user online"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p>Congratulate <strong>Olivia James</strong> for New template start</p><span>Oct 15 12:32pm</span>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p><strong>Joshua Gray</strong> New Message Received</p><span>Oct 13 02:56am</span>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user online"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p><strong>Elizabeth Lewis</strong> added new schedule realease</p><span>Oct 12 10:40pm</span>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-footer">
                        <a href="javascript:void(0)">View All Notifications</a>
                    </div>
                </div>
            </div> -->
            <!-- <div ngbDropdown class="main-header-notification">
                <a ngbDropdownToggle class="nav-link icon" href="javascript:void(0)">
                    <i class="fe fe-message-square header-icons"></i>
                    <span class="badge badge-success nav-link-badge">6</span>
                </a>
                <div ngbDropdownMenu>
                    <div class="header-navheading">
                        <p class="main-notification-text">3 new Messages</p>
                    </div>
                    <div class="main-notification-list">
                        <div class="media new">
                            <div class="main-img-user online"><img alt="avatar" src="g"></div>
                            <div class="media-body">
                                <p>Paul Molive
                                    <span>I'm sorry but i'm not sure how...</span>
                                </p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p>Sahar DaryAll
                                    <span>set ! Now, time to get to you now...</span>
                                </p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user online"><img alt="avatar" src="g"></div>
                            <div class="media-body">
                                <p>Barney Cull
                                    <span>Here are some products ...</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-footer">
                        <a href="javascript:void(0)">View All</a>
                    </div>
                </div>
            </div> -->

            <div ngbDropdown class="main-profile-menu">
                <a ngbDropdownToggle class="d-flex" href="javascript:void(0)">
                    <span class="main-img-user" >{{user.name | initials}}</span>
                </a>
                <div ngbDropdownMenu>
                    <div class="header-navheading">
                        <h6 class="main-notification-title">{{user.name}}</h6>
                        <p class="main-notification-text">{{user.email}}</p>
                    </div>
                    <a ngbDropdownItem class="border-top cursor-pointer" (click)="openModal(modalProfile)">
                        <i class="fe fe-user"></i> Mis Datos
                    </a>
                    <!-- <a ngbDropdownItem class="dropdown-item" routerLink="/pages/profile">
                        <i class="fe fe-edit"></i> Edit Profile
                    </a>
                    <a ngbDropdownItem class="" routerLink="/pages/profile">
                        <i class="fe fe-settings"></i> Account Settings
                    </a>
                    <a ngbDropdownItem class="" routerLink="/pages/profile">
                        <i class="fe fe-settings"></i> Support
                    </a>
                    <a ngbDropdownItem class="" routerLink="/pages/profile">
                        <i class="fe fe-compass"></i> Activity
                    </a> -->
                    <button type="button" ngbDropdownItem class="text-danger" (click)="signOutHandler()">
                        <i class="fe fe-power"></i> Sign Out
                    </button>
                </div>
            </div>
            <!-- <div class="dropdown d-md-flex header-settings">
                <a href="javascript:void(0)" class="nav-link icon" (click)="toggleSidebarNotification()">
                    <i class="fe fe-align-right header-icons"></i>
                </a>
            </div>
            <button class="navbar-toggler navresponsive-toggler" type="button" (click)="collapse.toggle()" 
                    [attr.aria-expanded]="!isCollapsed">
                <i class="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </button> Navresponsive closed -->
        </div>
    </div>
</div>
<!-- End Main Header-->

<!-- Mobile-header -->
<div class="mobile-main-header">
    <div class="mb-1 navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
        <div class="collapse navbar-collapse" id="navbarSupportedContent-4" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="d-flex order-lg-2 ml-auto">
                <div ngbDropdown class="header-search">
                    <a ngbDropdownToggle class="nav-link icon header-search">
                        <i class="fe fe-search header-icons"></i>
                    </a>
                    <div ngbDropdownMenu>
                        <div class="main-form-search p-2">
                            <div class="input-group">
                                <div class="input-group-btn search-panel">
                                    <ng-select placeholder="All categories" class="form-control p-0">
                                        <ng-option *ngFor="let category of categories" [value]="category.id">
                                            {{category.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <input type="search" class="form-control" placeholder="Search for anything...">
                                <button class="btn search-btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div ngbDropdown class="main-header-notification flag-dropdown">
                <a ngbDropdownToggle class="nav-link icon country-Flag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" fill="#f0f0f0"/><g fill="#0052b4"><path d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z"/></g><g fill="#d80027"><path d="M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z"/><path d="M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z"/></g></svg>
                </a>
                <div ngbDropdownMenu>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex ">
                        <div class="lang-span">FR</div>
                        <div class="d-flex">
                            <span class="mt-2">French</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">GE</div>
                        <div class="d-flex">
                            <span class="mt-2">Germany</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">IT</div>
                        <div class="d-flex">
                            <span class="mt-2">Italy</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">RS</div>
                        <div class="d-flex">
                            <span class="mt-2">Russia</span>
                        </div>
                    </a>
                    <a href="javascript:void(0)" ngbDropdownItem class="d-flex">
                        <div class="lang-span">ES</div>
                        <div class="d-flex">
                            <span class="mt-2">spain</span>
                        </div>
                    </a>
                </div>
            </div>
            <div class="dropdown">
                <a class="nav-link icon full-screen-link" href="javascript:void(0)" appFullscreenToggle >
                    <i class="{{toggleClass}} fullscreen-button fullscreen header-icons"></i>
                </a>
            </div>
            <div ngbDropdown class="main-header-notification">
                <a ngbDropdownToggle class="nav-link icon" href="javascript:void(0)">
                    <i class="fe fe-bell header-icons"></i>
                    <span class="badge badge-danger nav-link-badge">4</span>
                </a>
                <div ngbDropdownMenu class="dropdown-menu">
                    <div class="header-navheading">
                        <p class="main-notification-text">You have 1 unread notification<span class="badge badge-pill badge-primary ml-3">View all</span></p>
                    </div>
                    <div class="main-notification-list">
                        <div class="media new">
                            <div class="main-img-user online"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p>Congratulate <strong>Olivia James</strong> for New template start</p><span>Oct 15 12:32pm</span>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p><strong>Joshua Gray</strong> New Message Received</p><span>Oct 13 02:56am</span>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user online"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p><strong>Elizabeth Lewis</strong> added new schedule realease</p><span>Oct 12 10:40pm</span>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-footer">
                        <a href="javascript:void(0)">View All Notifications</a>
                    </div>
                </div>
            </div>
            <!---->
            <div ngbDropdown class="main-header-notification mt-2">
                <a ngbDropdownToggle class="nav-link icon" href="javascript:void(0)">
                    <i class="fe fe-message-square header-icons"></i>
                    <span class="badge badge-success nav-link-badge">6</span>
                </a>
                <div ngbDropdownMenu>
                    <div class="header-navheading">
                        <p class="main-notification-text">3 new Messages</p>
                    </div>
                    <div class="main-notification-list">
                        <div class="media new">
                            <div class="main-img-user online"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p>Paul Molive
                                    <span>I'm sorry but i'm not sure how...</span>
                                </p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p>Sahar DaryAll
                                    <span>set ! Now, time to get to you now...</span>
                                </p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="main-img-user online"><img alt="avatar" src=""></div>
                            <div class="media-body">
                                <p>Barney Cull
                                    <span>Here are some products ...</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-footer">
                        <a href="javascript:void(0)">View All</a>
                    </div>
                </div>
            </div>
            <!---->
            <div ngbDropdown class="main-profile-menu">
                <a ngbDropdownToggle class="d-flex" href="javascript:void(0)">
                    <span class="main-img-user" ><img alt="avatar" src=""></span>
                </a>
                <div ngbDropdownMenu>
                    <div class="header-navheading">
                        <h6 class="main-notification-title">Sonia Taylor</h6>
                        <p class="main-notification-text">Web Designer</p>
                    </div>
                    <a ngbDropdownItem class=" border-top" routerLink="/pages/profile">
                        <i class="fe fe-user"></i> My Profile
                    </a>
                    <a ngbDropdownItem class="" routerLink="/pages/profile">
                        <i class="fe fe-edit"></i> Edit Profile
                    </a>
                    <a ngbDropdownItem class="" routerLink="/pages/profile">
                        <i class="fe fe-settings"></i> Account Settings
                    </a>
                    <a ngbDropdownItem class="" routerLink="/pages/profile">
                        <i class="fe fe-settings"></i> Support
                    </a>
                    <a ngbDropdownItem class="" routerLink="/pages/profile">
                        <i class="fe fe-compass"></i> Activity
                    </a>
                    <a ngbDropdownItem class="" routerLink="/">
                        <i class="fe fe-power"></i> Sign Out
                    </a>
                </div>
            </div>
            <div class="dropdown  header-settings">
                <a href="javascript:void(0)" class="nav-link icon" (click)="toggleSidebarNotification()">
                    <i class="fe fe-align-right header-icons"></i>
                </a>
            </div>
            </div>
        </div>
    </div>
</div>
<!-- End Mobile-header -->

<div class="jumps-prevent" style="padding-top: 64px;"></div>

<ng-template #modalProfile>
    <app-modal-profile></app-modal-profile>
</ng-template>