import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'user-img-modal',
  templateUrl: './user-img-modal.component.html',
  styleUrls: ['./user-img-modal.component.scss']
})
export class UserImgModalComponent implements OnInit {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public close(): void { this.onClose.emit() }
  public apiUrl = environment.apiUrl;
  public img: string = ''; 
  public urlImage: string = '';
  public noImage: string = ''; 
  public FormUser: FormGroup;  
  private imagesSelected = [];
  public file: any;


  public profileInstructor = [
    { index: 0, id: null, name: null, action: ""}, 
  ];
  
  
  constructor(private httpService: HttpService,
              private alertService: AlertService,
              public userService: UserService, 
              private fb: FormBuilder
    ) { this.img = `${this.apiUrl}${this.userService.userEmployeeExam.image}`;
        this.profileInstructor[0].name  =  `${this.apiUrl}${this.userService.userEmployeeExam.image}`}

  ngOnInit(): void {
    this.noImage = '../../../assets/img/placeholder-user.png';  
    this.img = `${this.apiUrl}${this.userService.userEmployeeExam.image}`;
    this.formUser();
  }

  formUser(){
    this.FormUser = this.fb.group({
      image: []
    });
  }
 
  selectImage(previous, event, remplace = false) { 
    if (event.target.files[0].size < 5242880) {
      //Tomar imagen del input
      let file: File = event.target.files[0];
      // Imagen seleccionada
      this.imagesSelected[previous.index] = file;  
      // Imagen seleccionada 
      var reader = new FileReader();
      reader.readAsDataURL(file);  
      
      //Mostrar preview de la imagen y guardar en formulario.
      reader.onload = (event) => {
        this.profileInstructor[previous.index].name = event.target.result as string;
      }

      this.FormUser.get("image").setValue(file); 

    }else{
      this.alertService.infoToast("La imagen debe ser menor a 5MB.");
      event.target.value = '';
    }
  }

  updateUserImage(){ 
    let formData = new FormData();
    this.buildFormData(formData, this.FormUser.value); 
    return this.httpService.put(`api/employee/image`, formData).toPromise()
    .then((resp: any) => {
      this.img = `${this.apiUrl}${resp.image}`;
      this.userService.setImage(resp.image); 
      this.userService.setData(this.img); 
      this.close();
      this.alertService.successToast(resp.message);
      
    }).catch((err: any) => {
      if (err?.status != 403)this.alertService.infoToast("Al menos una imagen es requerida !");  
    });

  }
  
  deleteImage(index, id) { 
    //Borrar la imagen previe en crear
    this.imagesSelected[this.profileInstructor[index].index] = null;
  
    this.profileInstructor[index].action = "DELETE";
    this.profileInstructor[index].name = null;
    this.profileInstructor[index].id = null;
  
  }



  buildFormData(formData, data, parentKey?) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {

        // this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }


}
