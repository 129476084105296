import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesLoadPipe } from './images-load.pipe';
import { DecryptPipe } from './decrypt.pipe';
import { statusPipe } from './status.pipe';
import { momentPipe } from './moment.pipe';
import { InitialsPipe } from './initials.pipe';
import { formatSecondsPipe } from './format-seconds';
import { bytesConvertPipe } from './bytes-convert.pipe';
import { ImageUserPipe } from './image-user.pipe';
import { TranslatePipe } from './translate.pipe';



@NgModule({
  declarations: [ImagesLoadPipe, DecryptPipe, statusPipe , momentPipe, InitialsPipe, formatSecondsPipe, bytesConvertPipe, ImageUserPipe, TranslatePipe],
  imports: [],
  exports: [ImagesLoadPipe, DecryptPipe, statusPipe, momentPipe,InitialsPipe, formatSecondsPipe, bytesConvertPipe, ImageUserPipe, TranslatePipe], 
})
export class PipesModule { }
