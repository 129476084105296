<div class="img"><img [src]="img | imagesLoad" alt=""></div>
<div class="info border-left">
    <div class="title border-bottom">{{item.title}}</div>
    <div class="bg-white d-flex flex-column">
        <div class="desc" *ngIf="showDesc">{{item.description}}</div>
        <div class="desc" *ngIf="showProg">
            Terminados: {{item.courses_done}} de {{item.total_courses}}
        </div>
        <div class="css_bar" *ngIf="showProg">
            <div class="fill" [style.width.%]="(item.courses_done / item.total_courses) * 100"></div>
            <div class="fill pending" [style.width.%]="(item.pendingExam / item.total_courses) * 100"></div>
        </div>
        <div class="date text-primary">
            <span class="text-primary"><i class="fa fa-calendar mr-2 op-5"></i>Desde <b>{{item.start.dateStart | moment:'l'}}</b> Hasta <b>{{item.start.dateEnd | moment:'l'}}</b></span>
        </div>
        <div class="bot mb-2">
            <span class="bdg"><i class="fa fa-bookmark mr-1"></i> {{item.courseQTY}} Cursos</span>
            <span class="text-muted">Presiona para ver <i class="fa fachevron-right"></i></span>
        </div>
    </div>
</div>