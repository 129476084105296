
<div class="card custom-card _head">
    <div class="_bg"><img [src]="img | imagesLoad" alt=""></div>
    <div class="row">
        <div class="col-12 d-flex justify-content-between flex-wrap">
            <div class="card border-0 _cert bg-white-9">
                <div class="info">
                    <div class="title">{{detailCertification?.title}}</div>
                    <div class="desc op-7">{{detailCertification?.description}}</div>
                    <div class="date d-flex justify-content-between mt-3 text-primary">
                        <span><i class="fa fa-calendar mr-2"></i> Desde <b>{{detailCertification?.start?.dateStart | moment:'ll'}}</b> hasta <b>{{detailCertification?.start?.dateEnd | moment:'ll'}}</b></span>
                        <!-- <b><i class="fa fa-building mr-2"></i> {{detail?.address || 'Sin Lugar'}}</b> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 d-flex justify-content-between">
        <div class="user">
            <div class="_user d-flex">
                <div class="img"><img [src]="imgInstitution | imagesLoad"></div>
            </div>
            <div class="info">
                <b>Institución</b>
                <span>{{detailCertification?.institutionData?.name}}</span>
            </div>
        </div>
        <!-- <h1>status: {{  detailCertification?.status }}</h1>
        <div class="_addbtn" *ngIf="!isJoin">
            <button class="btn btn-lg btn-info" *ngIf="!detailCertification?.status || detailCertification?.status == 'CANCELLED'" [swal]="activeSwal" (confirm)="joinCertification(detailCertification._id)" > <i class="fa fa-user mr-2"></i><b>Inscribirme</b></button>
            <button class="btn btn-lg btn-danger" *ngIf="detailCertification?.status == 'APPROVED'" [swal]="cancelSwal" (confirm)="cancelCertification(join_id)" > <i class="fa fa-close mr-2"></i><b>Cancelar inscripcion</b></button>
            <button class="btn btn-lg btn-danger" *ngIf="detailCertification?.status == 'PENDING'"[swal]="cancelSwal" (confirm)="cancelCertification(join_id)" > <i class="fa fa-close mr-2"></i><b>Cancelar Solicitud</b></button>
            <span class="btn btn-lg btn-dark" *ngIf="detailCertification?.status == 'DONE'"> <i class="fa fa-user mr-2"></i><b>{{'Finalizada' | titlecase}}</b></span>
        </div> -->
    </div>
</div>
<h5 class="my-3 text-primary"><i class="fa fa-list mr-2"></i> Cursos de {{nameCertification}}</h5>
<div class="row">
    <div class="col-sm-12" *ngFor="let course of detailCertification?.coursesData">
        <course-card [item]="course" (click)="(course?.start?.active)?openCourseDetails(course, courseModal):'';"></course-card>
    </div>
</div>

<ng-template #courseModal let-modal>
    <!-- <course-detail (onClose)="modal.dismiss()" [modalOptions]="modalOptions"></course-detail> -->
    <detail-course [options]="modalOptions" (onCourseClose)="modal.dismiss();"></detail-course>
    
</ng-template>
 