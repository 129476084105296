import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatSeconds'
})
export class formatSecondsPipe implements PipeTransform {

  constructor(){}

  transform(seconds: number): any {
    return new Date(seconds * 1000).toISOString().substr(14, 5)
  }
  
}