import { Routes } from '@angular/router'; 
import { RoleGuard } from '../services/role-guard.service';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const Pages_Routes: Routes = [
    {
        path: '',
        // canActivate: [RoleGuard],
        loadChildren: () => import('../../pages/calendar/calendar.module').then(m => m.CalendarModule)
    },
    // {
    //     path: 'home',
    //     // canActivate: [RoleGuard],
    //     loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    // },
    {
        path: 'certifications',
        // canActivate: [RoleGuard],
        loadChildren: () => import('../../pages/certifications/certifications.module').then(m => m.CertificationsModule)
    },
    {
        path: 'calendar',
        // canActivate: [RoleGuard],
        loadChildren: () => import('../../pages/calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
        path: 'kardex',
        // canActivate: [RoleGuard],
        loadChildren: () => import('../../pages/kardex/kardex.module').then(m => m.KardexModule)
    },
    // {
    //     path: 'supports',
    //     // canActivate: [RoleGuard],
    //     // data: { role: 'supports'},
    //     loadChildren: () => import('../../pages/supports/supports.module').then(m => m.SupportsModule)
    // },
    {
        path: 'web-exam',
        // canActivate: [RoleGuard],
        // data: { role: 'supports'},
        loadChildren: () => import('../../pages/web-exam/exam.module').then(m => m.ExamModule)
    } 
                                  
]