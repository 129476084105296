import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILogin, IMobile, IUser } from 'src/app/interfaces/iterfaces';
// import { ILogin } from 'src/app/interfaces/ilogin';
// import { IUser } from 'src/app/interfaces/iuser';
import { environment } from 'src/environments/environment';
import { CryptoSharkService } from './crypto-shark.service';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenInterceptorService } from './token.interceptor.service';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/** 
 * Servicio administrador de sesión de usuario
 */
export class UserService { 
  public _employeeExam: any = {}
  public apiUrl = environment.apiUrl;
  public img: string = '';
  private dataSource = new BehaviorSubject<string>(`${this.img}`)
  public data = this.dataSource.asObservable();
  public security:any;
  private _login: ILogin = {}
  private _user: IUser = {}
  
  public mensaje: string = '';
  private _userMobile: any = {};
  
  constructor(private httpService: HttpService,
              private router: Router,
              private cryptoService: CryptoSharkService,
              private http: HttpClient) { }

  /**
   * Guardar los datos del usuario
   * @param login Credenciales del usuario que inició sesión
   */
  signInCurrentUser(login: ILogin) {
    localStorage.setItem('ldt', JSON.stringify(login));
  }

  /**
   * Obtener datos de la sesión del usuario
   */
  get login() {
    return this._login
  }

  /**
   * Obtener datos del usuario
   */
  get user() {
    return this._user
  } 

  get userEmployeeExam() {
    return this._employeeExam
  }

  get userMobile(){
    return this._userMobile
  }

  /**
   * Obtener datos de sesión del usuario validUser()
   * @param returnUrl Url a la que regresará una vez que haya iniciado sesión
   */
  setImage(img: string){
    this._employeeExam.image = img;
  }

  public searchUser(data: any): Promise<boolean>{
    return new Promise((resolve, reject) =>{ 
      this.httpService.post(`api/login/employee`, data).subscribe((resp: any)=>{
        this.security = resp.security || null;
        resolve(resp.isRegistered?true:false);
      },err=>{ reject(err) });
    })
  }


   
  getCurrentUser(returnUrl: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._login = JSON.parse(localStorage.getItem(`ldt`));

      if (!this._login) {
        this.router.navigate(['/login'], {queryParams: { returnUrl } });
        resolve(false);
      }
      
      environment.apiUrl = this._login.company.server;
      this.httpService.get(`api/login`).subscribe( async ({employee, datakey, key}: any) =>{ 
        this._login.company.dk = this.cryptoService.decrypt(datakey, key);
 
        let nombre = this.cryptoService.decrypt(employee.name, this._login.company.dk );
        this._employeeExam.number = this.cryptoService.decrypt(employee.number, this._login.company.dk);
        this._employeeExam.name = nombre;
        this._employeeExam.position = employee.position;
        this._employeeExam.department = employee.department;
        this._employeeExam.image = employee.image;
        this.img = `${this.apiUrl}${employee.image}`;  
        this._user.nameCertification = employee.titleCert || "..."; 
        this.dataSource.next(`${this.apiUrl}${employee.image}`);
        

        this._user.name = employee.name ? this.cryptoService.decrypt(employee.name, this._login.company.dk ): "";
        this._user.email = employee.email ? this.cryptoService.decrypt(employee.email, this._login.company.dk) : "";
        this._userMobile.phone = employee.phone ? this.cryptoService.decrypt(employee.phone, this._login.company.dk): "";
        this._userMobile.rfc = employee?.rfc ? this.cryptoService.decrypt(employee?.rfc, this._login.company.dk) : "";
        this._userMobile.nss = employee.nss ? this.cryptoService.decrypt(employee.nss, this._login.company.dk) : "";
        
      
        resolve(true) // Resolver promesa para dejar pasar 
         
      }, err=>{ reject(true) });
      
    })
  }

  setData(data){
    this.dataSource.next(data);
  }

   
  getSubdomain():string { 
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === '45' ||
      domain.split('.')[0] === '10'    ) {
      return 'local';
    } else {
      environment.subdomain = domain.split('.')[0];
      let companyName =  domain.split('.')[0]?.split('-')[0] || '';
      return companyName;
    }
  } 
  

  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
  signOutCurrentUser() {
    // environment.apiUrl = 'http://10.10.2.34:8000/'
    localStorage.removeItem('ldt')
    this.router.navigate(['/login'])
  }

  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
   signOutCurrentUserExam() {
    // environment.apiUrl = 'http://10.10.2.34:8000/'
    localStorage.removeItem('ldt')
    this.router.navigate(['/examen'])
  }
}
