<div class="modal-body py-0">
<div class="row">
    <div class="col-12 _detail pb-3">
            <div class="card custom-card bg-primary _head mt-3">
                <div class="_bg"><img src="../../../assets/img/trianglePattern.png" alt=""></div>
                <div class="row">
                    <div class="col-12 d-flex justify-content-between flex-wrap ">
                        <div class="card border-0 _cert bg-white-9">
                            <div class="labels d-flex justify-content-between">
                                <span class="m-0">Duración: {{detail?.duration}} hrs.</span>
                                <span><i class="fa fa-circle mr-2 {{detail?.type | lowercase}}"></i> {{typeName[detail?.type]}}</span>
                            </div>
                            <div class="info">
                                <div class="title">{{detail?.title}}</div>
                                <div class="desc op-7">{{detail?.description}}</div>
                                <div class="date d-flex justify-content-between mt-3 text-primary">
                                    <b class="mr-2"><i class="fa fa-calendar mr-1"></i> {{detail?.availableDate?.dateStart | moment:'LLL'}}</b>
                                    <b><i class="fa fa-building mr-1"></i> {{detail?.address || 'Sin Lugar'}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="user">
                    <div class="_user">
                        <div class="img"><img [src]="img | imagesLoad" alt=""></div>
                    </div>
                    <div class="info">
                        <b>Instructor</b>
                        <span>{{detail?.certificateBy.user | titlecase}}</span>
                    </div>
                </div>
                <div class="user">
                  <div class="info text-right">
                      <b>Institución</b>
                      <span>{{detail?.certificateBy.institutionName || detail?.certificateBy.institution || ''}} </span>
                  </div>
                    <div class="_user">
                        <div class="img"><img [src]="imgInstitution | imagesLoad" alt=""></div>
                    </div>
                </div>
            </div>
            
            <!-- BOTONES ASISTENCIA -->
            <ng-container *ngIf="false">
                <div class="mt-3">
                  <button class="btn btn-info mr-2"><i class="fa fa-check mr-2"></i> Asistencia</button>
                  <button class="btn btn-success"><i class="fa fa-user mr-2"></i> Examenes</button>
                </div>
            </ng-container>
  
            <ng-container *ngIf="detail?.generalObjetive?.length">
                <h3 class="text-center text-primary my-3">Objetivo general</h3>
                <span class=" text-justify">{{detail?.generalObjetive}}</span>
            </ng-container>
            <ng-container *ngIf="detail?.specificObjetive?.length">
                <h3 class="text-center text-primary my-3">Objetivo específico</h3>
                <span class=" text-justify">{{detail?.specificObjetive}}</span>
            </ng-container>
            <div class="row">
                <div class="col-{{detail?.files?.length ? 6 : 12}}" *ngIf="detail?.status == 'APPROVED'">
                  <h3 class="text-center text-primary my-3">Puntos importantes</h3>
                  <div class="card custom-card px-3">
                      <div class="row" [class.border-top]="i" *ngFor="let c of detail?.index; index as i"><div class="col-12 py-2"><b><span class="text-info mr-2">{{i + 1}})</span>{{c}}</b></div></div>
                      <div class="row" *ngIf="!detail?.index?.length"><div class="col-12 text-muted py-2">Sin puntos importantes</div></div>
                  </div>
                </div>
                <div class="col-6" *ngIf="detail?.status == 'APPROVED' && detail?.files?.length && !validateStartCourse(detail)">
                    <h3 class="text-center text-primary my-3">Archivos</h3>
                    <!-- <div class="card custom-card">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <tbody>
                                    <tr *ngFor="let file of detail.files">
                                        <td>
                                            <div class="d-flex justify-content-between">
                                                <span>{{file | json}}</span>  
                                                <b>{{file?.nameOriginal}}</b>
                                                <a href=""></a>
                                                <button class="btn btn-sm btn-dark btn-circle m-0" (click)="download(file?.name, file?.nameOriginal)"><i class="fa fa-download"></i></button>
                                                <a href="{{apiUrl}}{{file?.name}}" class="btn btn-sm btn-dark btn-circle m-0" download="{{file?.nameOriginal}}" target="_blank"><i class="fa fa-download"></i></a> 
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
  
                    <div class="d-flex flex-wrap justify-content-between cursor-pointer">
                      <div class="card custom-card mx-0 mt-0 mb-2 _file" *ngFor="let file of detail.files" (click)="download(file?.name, file?.nameOriginal)">
                          <h5 [title]="file?.nameOriginal">{{file?.nameOriginal.split('.')[0]}}</h5>
                        <div class="info">
                            <span>{{file?.nameOriginal.split('.')[1]}}</span>
                            <span><i class="fa fa-download mr-1"></i> {{file.size | bytesConvert}}</span>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="detail?.status == 'APPROVED' && !validateStartCourse(detail)">
                <h3 *ngIf="detail.link" class="text-center text-primary my-3">Link</h3>
                <div *ngIf="detail.link" class="card custom-card px-3 py-2 _examBtn" (click)="goToLink(detail?.link)">
                    <div class="icon"><i class="fa fa-link op-7 fa-4x"></i></div>
                    <div class="info pl-3">
                        <div class="center">
                            <h3>Ir al link</h3>
                            <span>Presiona para ir</span>
                        </div>
                    </div>
                    <div class="arrow"><i class="fa fa-chevron-right"></i></div>
                </div>
            </ng-container> 

            <ng-container *ngIf="detail?.status == 'APPROVED'">
                <h3 *ngIf="detail?.exam" class="text-center text-primary my-3">Examenes</h3>
                <ng-container *ngFor="let item of detail?.exam">
                    <div *ngIf="item?.name != 'EXAMEN NO REQUERIDO'" class="card custom-card p-3 _examBtn" [class.disabled]="!detail?.startExam || item?.status == 'DONE'" [swal]="startSwal" (confirm)="openExam(item.id)"  [attr.band-label]="item?.name != 'EXAMEN NO REQUERIDO' ? 'Proximamente' : 'No Requerido'" [class.__band]="!detail?.startExam && item?.name != 'EXAMEN NO REQUERIDO'">
                        <div class="title" [class.opacity]="!detail.startExam">
                            <div class="top" *ngIf="!showResult">
                                <span>Presiona para ir</span>
                                <span>Intentos: <b>{{item.exerciseUse || 0}} / {{ item.exerciseLimit || 0}}</b></span>
                            </div>
                            <h3 class="d-flex justify-content-between m-0">
                                <span>Contestar Examen</span>
                                <span class="op-6"><i class="fa fa-chevron-right"></i></span>
                            </h3>
                        </div>
                    </div>
                </ng-container>
            </ng-container> 
    </div>
  </div>
</div>

<div class="modal-footer justify-content-{{this.options.type != 'EXAM' ? 'between' :'end'}}">
    <button class="btn btn-dark" *ngIf="this.options.type != 'EXAM'" (click)="close()">Cerrar</button>
    <div class="_addbtn">
        <div class="btn-group">
            <ng-container *ngIf="!isJoin">
                <button class="btn btn-info" *ngIf="!detail?.status || detail.status  == 'CANCELLED'" [swal]="activeSwal" (confirm)="joinCertification(detail._id, detail.certificateid)" > <i class="fa fa-user mr-2"></i>Inscribirme</button>
                <button class="btn btn-danger" *ngIf="detail?.status == 'PENDING'"[swal]="cancelSwal" (confirm)="cancelCertification(detail.idjoin)" > <i class="fa fa-close mr-2"></i>Cancelar Solicitud</button>
            </ng-container>
            <button class="btn btn-danger" *ngIf="detail?.status == 'APPROVED'" [swal]="cancelSwal" (confirm)="cancelCertification(detail.idjoin)" > <i class="fa fa-close mr-2"></i>Cancelar inscripción</button>
        </div>
        <span class="text-suxxess my-auto" *ngIf="detail?.status == 'DONE'"> <i class="fa fa-checkmark mr-2"></i>{{'Finalizada' | titlecase}}</span>
    </div>
</div>

<swal #startSwal class="d-none" title="¿Estas seguro de iniciar tu examen?" text="Al iniciar contará como un intento." icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>

<swal #activeSwal class="d-none" title="¿Estas seguro de inscribirte a {{nameCertification}}?" icon="question" [showCancelButton]="true" [focusCancel]="true"  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#28a745'}">
</swal>

<swal #cancelSwal class="d-none" title="¿Estas seguro de cancelar tu inscripción a {{nameCertification}}?" icon="question" [showCancelButton]="true" [focusCancel]="true"  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#28a745'}">
</swal>